import Image from 'next/legacy/image';
import React, { FC } from 'react';
import { Box, Flex } from '@rebass/grid';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import styled from 'styled-components';
import Link from './Link';
import { useRouter } from 'next/router';

const passportImageUrl = {
    nl: '/static/img/passport-block-nl.jpg',
    de: '/static/img/passport-block-de.jpg',
    en: '/static/img/passport-block-en.jpg',
};
export const texelAppLink = {
    nl: '/naar-texel/texel-app/',
    en: '/to-texel/texel-app/',
    de: '/nach-texel/texel-app/',
};
const TexelPassport: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage } = useIntl();

    const { locale } = useRouter();

    return (
        <Container
            my={[6, 8]}
            pt={[6, 8]}
            flexDirection={['column', null, 'row']}
            justifyContent="space-around"
            alignItems="center"
        >
            <Link href={texelAppLink[(locale ?? 'nl') as keyof typeof texelAppLink]}>
                <StyledBoxImage width={[1, 1, 1, 1 / 2]} mb={[0, 0, 6, 0]}>
                    <Image
                        loading="lazy"
                        src={passportImageUrl[(locale ?? 'nl') as keyof typeof passportImageUrl]}
                        alt={formatMessage({ defaultMessage: 'Texel Paspoort' })}
                        layout="responsive"
                        width={674}
                        height={403}
                    />
                </StyledBoxImage>
            </Link>
            <Box mt={[5, null, 0]} width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]} ml={[0, 0, 0, 7]}>
                <Title variant="large">
                    <FormattedMessage defaultMessage="Voordeel met de @texel app" />
                </Title>
                <StyledBox mt={[2, null, 5]}>
                    <Body variant="regular">
                        <FormattedMessage
                            defaultMessage="Op vakantie naar Texel? Download dan nu gratis de {texelAppLink}. Met de vouchers in de app krijg je korting of een leuke extra bij Texelse ondernemers."
                            values={{
                                texelAppLink: (
                                    <Link href={texelAppLink[(locale ?? 'nl') as keyof typeof texelAppLink]}>
                                        <a>@texel app</a>
                                    </Link>
                                ),
                            }}
                        />
                    </Body>
                </StyledBox>
            </Box>
        </Container>
    );
};

const Container = styled(Flex)`
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        align-items: center;
    }
`;
const StyledBox = styled(Box)`
    p {
        margin-bottom: 0.8rem;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
            margin-bottom: 1.6rem;
        }
    }
`;

const StyledBoxImage = styled(Box)`
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.primary['20']};
`;
export default TexelPassport;
